import React, { useState, useEffect } from "react";

import { Row, Col, Switch, Select, Space, message } from "antd";
import post from "images/landing-page/post-image.svg";
import postShadow from "images/business.svg";
import phoneImg from "images/shippy/phone.svg"
import mailImg from "images/shippy/mail.svg"
import { Formik, useFormikContext } from "formik";
import { Form, Input, SubmitButton, Field } from "formik-antd";
import Request from "../../helpers/Request";
import Aos from "aos";
import "aos/dist/aos.css";
import * as yup from "yup";
import { useMediaQuery } from "react-responsive";
import BusinessModal from "../business_pages/components/BusinessForm";
import SuccessModal from "../business_pages/components/SuccessModal";

const { TextArea } = Input;

const BusinessForm = () => {
  const isMobileScreen = useMediaQuery({ query: "(max-width: 992px)" });
  const [isFormModalVisible, setIsFormModalVisible] = useState(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);

  return (
    <>
      <Row
        style={{
          marginTop: isMobileScreen ? 20 : 200,
          backgroundColor: "#F7FBFD",
          marginBottom: -200,
        }}
      >
        <Col offset={2} span={20}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              data-aos={isMobileScreen ? "" : "zoom-in"}
              style={{
                width: "100%",
                maxWidth: 1200,
              }}
            >
              <Row>
                <Col
                  offset={0}
                  span={24}
                  order={1}
                  lg={{ offset: 0, span: 12, order: 0 }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      overflow: "clip",
                      marginBottom: 30,
                    }}
                  >
                    <img
                      src={postShadow}
                      style={{
                        padding: "3rem",
                        width: isMobileScreen ? "100%" : "80%",
                      }}
                    />
                  </div>
                </Col>

                <Col
                  order={0}
                  offset={2}
                  span={20}
                  lg={{ offset: 1, span: 10, order: 1 }}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    gap: "15px",
                  }}
                >
                  <div
                    className="section-title"
                    style={{
                      marginTop: "28px",
                      textAlign: isMobileScreen ? "center" : "left",
                    }}
                  >
                    {"Reikia verslui pritaikytų sprendimų?"}
                  </div>
                  <button
                    className="btn btn-primary-alsendo btn-lg btn-circle"
                    onClick={() => {
                      setIsFormModalVisible(true);
                    }}
                  >
                    {"Gauti pasiūlymą"}
                  </button>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
      {isFormModalVisible && (
        <BusinessModal
          setIsFormModalVisible={setIsFormModalVisible}
          setIsSuccessModalVisible={setIsSuccessModalVisible}
        />
      )}
      {isSuccessModalVisible && (
        <SuccessModal setIsSuccessModalVisible={setIsSuccessModalVisible} />
      )}
    </>
  );
};

export default BusinessForm;
