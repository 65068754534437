import React, { useState, useEffect } from "react";
import Request from "../../../helpers/Request";
import { Modal } from "antd";
import { Space } from "antd";
import { Formik } from "formik";
import * as yup from "yup";
import { Form, Input, Select, Field } from "formik-antd";

const { TextArea } = Input;

const BusinessModal = ({ setIsFormModalVisible, setIsSuccessModalVisible }) => {
  const initialValues = {
    offer: {
      company: "",
      company_code: "",
      business: "",
      selling_platforms: "",
      name: "",
      email: "",
      phone: "",
      package_amount: "",
      main_countries: "",
      average_weight: "",
      comments: "",
    },
  };

  const businessFormSchema = yup.object().shape({
    offer: yup.object().shape({
      email: yup
        .string()
        .max(50, "Max 50 simbolių")
        .matches(/^([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@([0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)*|\[((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|IPv6:((((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){6}|::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){5}|[0-9A-Fa-f]{0,4}::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){4}|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):)?(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){3}|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,2}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){2}|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,3}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,4}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::)((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3})|(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3})|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,5}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3})|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,6}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::)|(?!IPv6:)[0-9A-Za-z-]*[0-9A-Za-z]:[!-Z^-~]+)])$/, "Neteisingas el. pašto adresas")
        .required("Privaloma"),
      phone: yup.string().required("Privaloma"),
    }),
  });

  return (
    <>
      <Modal
        visible
        footer={false}
        onCancel={() => {
          setIsFormModalVisible(false);
        }}
        maskClosable={false}
      >
        <div className="header-title-3" style={{ marginBottom: 20 }}>
          {"Gauti verslo pasiūlymą"}
        </div>
        <Formik
          onSubmit={(
            data,
            { setSubmitting, setTouched, setErrors, setFieldValue }
          ) => {
            Request("/business_pages/business_offer_email", "POST", {
              body: data,
            }).then(() => {
              setIsFormModalVisible(false);
              setIsSuccessModalVisible(true);
            });
          }}
          initialValues={initialValues}
          validationSchema={businessFormSchema}
        >
          {({
            isSubmitting,
            isValid,
            setFieldValue,
            errors,
            values,
            touched,
            submitForm,
            isInitialValid,
            validateForm,
            setSubmitting,
          }) => (
            <Form>
              <Form.Item name="offer[company]">
                <Input
                  name="offer[company]"
                  size="large"
                  style={{ height: "40px" }}
                  placeholder="Įmonės pavadinimas"
                />
              </Form.Item>
              <Form.Item name="offer[company_code]">
                <Input
                  name="offer[company_code]"
                  size="large"
                  style={{ height: "40px" }}
                  placeholder="Įmonės kodas ar individualios veiklos pavadinimas"
                />
              </Form.Item>
              <Form.Item name="offer[business]">
                <Input
                  name="offer[business]"
                  size="large"
                  style={{ height: "40px" }}
                  placeholder="Veikla"
                />
              </Form.Item>
              <Form.Item name="offer[selling_platforms]">
                <Input
                  name="offer[selling_platforms]"
                  size="large"
                  style={{ height: "40px" }}
                  placeholder="Pardavimo kanalai  (Ebay, Amazon, ETSY, el. parduotuvė ir pan.) "
                />
              </Form.Item>
              <Form.Item name="offer[name]">
                <Input
                  name="offer[name]"
                  size="large"
                  style={{ height: "40px" }}
                  placeholder="Kontaktinis asmuo"
                />
              </Form.Item>
              <Form.Item name="offer[email]">
                <Input
                  name="offer[email]"
                  size="large"
                  style={{ height: "40px" }}
                  placeholder="Kontaktinis el. paštas"
                />
              </Form.Item>
              <Form.Item name="offer[phone]">
                <Input
                  name="offer[phone]"
                  size="large"
                  style={{ height: "40px" }}
                  placeholder="Kontaktinis telefono numeris"
                  onChange={(e) => {
                    const characters = e.target.value.split("");
                    let phone = characters
                      .filter((chr) => chr.match(/[0-9]/))
                      .join("");

                    if (characters[0] == "+") phone = characters[0] + phone;

                    setFieldValue("offer[phone]", phone);
                  }}
                />
              </Form.Item>
              <Form.Item name="offer[package_amount]">
                <Input
                  name="offer[package_amount]"
                  size="large"
                  style={{ height: "40px" }}
                  placeholder="Vidutinis siuntų skaičius per mėnesį"
                />
              </Form.Item>
              <Form.Item name="offer[main_countries]">
                <Input
                  name="offer[main_countries]"
                  size="large"
                  style={{ height: "40px" }}
                  placeholder="Šalys, į kurias bus siunčiamos siuntos"
                />
              </Form.Item>
              <Form.Item name="offer[main_cities]">
                <Input
                  name="offer[main_cities]"
                  size="large"
                  style={{ height: "40px" }}
                  placeholder="Miestas, iš kurio bus siunčiamos siuntos"
                />
              </Form.Item>
              <Form.Item name="offer[average_weight]">
                <Input
                  name="offer[average_weight]"
                  size="large"
                  style={{ height: "40px" }}
                  placeholder="Vidutinis siuntų svoris (nuo-iki) kg. "
                />
              </Form.Item>
              <Form.Item name="offer[comments]">
                <TextArea name="offer[comments]" placeholder="Komentarai" />
              </Form.Item>
              <Form.Item name="submit">
                <button className="btn btn-primary-alsendo btn-lg btn-circle" type="submit">
                  {"Gauti pasiūlymą"}
                </button>
              </Form.Item>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default BusinessModal;
