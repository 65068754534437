import React from "react"

const BulletPointTicket = () => {
  return (
    <>
      <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.5 0C3.80568 0 0 3.80568 0 8.5C0 13.1943 3.80568 17 8.5 17C13.1943 17 17 13.1943 17 8.5C17 3.80568 13.1943 0 8.5 0ZM12.1844 7.06273C12.2522 6.98518 12.3039 6.89484 12.3363 6.79704C12.3687 6.69923 12.3812 6.59593 12.3731 6.49322C12.365 6.3905 12.3365 6.29044 12.2891 6.19892C12.2418 6.1074 12.1766 6.02627 12.0975 5.96031C12.0183 5.89435 11.9268 5.84488 11.8282 5.81482C11.7297 5.78476 11.6261 5.77472 11.5236 5.78528C11.4211 5.79584 11.3217 5.82679 11.2314 5.87631C11.141 5.92583 11.0615 5.99292 10.9975 6.07364L7.67473 10.0601L5.95541 8.34004C5.80967 8.19929 5.61448 8.1214 5.41187 8.12316C5.20927 8.12492 5.01546 8.20619 4.87219 8.34946C4.72892 8.49273 4.64765 8.68654 4.64589 8.88914C4.64413 9.09175 4.72201 9.28694 4.86277 9.43268L7.18095 11.7509C7.25688 11.8267 7.34778 11.886 7.44787 11.9248C7.54795 11.9636 7.65502 11.9811 7.76225 11.9762C7.86949 11.9714 7.97453 11.9442 8.07068 11.8965C8.16684 11.8488 8.25199 11.7815 8.32073 11.6991L12.1844 7.06273Z" fill="#6D3ADF"/>
</svg>

    </>
  )
}

export default BulletPointTicket
